import React from 'react';

function IdentifierField(props) {
  return (
    <div className='input_wrapper'>
      <input
        type='text'
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value) }
      />
    </div>
  );
}

export default IdentifierField;
