import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import LoginForm from 'components/login_form'

document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('login_form')
  const props = JSON.parse(form.dataset.props)
  ReactDOM.render(<LoginForm {...props} />, form)

  if (window.loadedPacks) { window.loadedPacks.push('login') }
})
