import React from 'react';
import IdentifierField from './login_form/identifier_field';
import PasswordField from './login_form/password_field';
import SubmitButton from './login_form/submit_button';
import Modal from 'react-modal';
import GoogleRecaptcha from '../models/google_recaptcha'
import InfoOutlinedIcon from '../images/info_outlined.svg'

const modalStyles = {
  content : {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)'
  }
};

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessages: props.initialErrorMessages ? props.initialErrorMessages.map((m) => ({ message: m })) : [],
      identifier: props.identifier.value || '',
      password: props.password.value || '',
      passwordMask: true,
      loading: false,
      modalIsOpen: false,
      recaptchaToken: null,
    };
  }

  componentDidMount() {
    this.initializeRecaptchaToken();
  }

  initializeRecaptchaToken() {
    const grecaptcha = new GoogleRecaptcha(this.props.recaptchaSiteKey, this.props.recaptchaAction);
    grecaptcha.getRecaptchaToken(token => {
      this.setState({recaptchaToken: token});
    });
  }

  togglePasswordMask(e) {
    e.preventDefault();
    this.setState({passwordMask: !this.state.passwordMask});
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({loading: true});
    const form = e.target;
    const formData = new FormData(form);
    formData.append(this.props.csrfParam, this.props.csrfToken);
    formData.append('google_recaptcha_token', this.state.recaptchaToken);

    fetch(form.action, {
      credentials: 'same-origin',
      method: 'POST',
      body: formData
    }).then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          location.href = json.redirect_url;
        });
      } else if (response.status === 401) {
        response.json().then(json => {
          this.setState({errorMessages: json.errorMessages, loading: false});
        }, () => {
          this.setState({errorMessages: ['予期しないエラーが発生しました。', '画面を再度読み込んでください。'], loading: false});
          throw 'recieve invalid format';
        });
        this.initializeRecaptchaToken();
      } else if (response.status === 422) {
        this.setState({modalIsOpen: true});
        this.initializeRecaptchaToken();
      } else {
        this.setState({errorMessages: ['予期しないエラーが発生しました。', 'ご不便をおかけし申し訳ございません。しばらく時間を置いてアクセスしてください。'], loading: false});
        this.initializeRecaptchaToken();
        throw `Unexpected response: ${response.status} (${response.statusText})`;
      }
    });
  }

  renderErrorMessages() {
    return (
    <div className='errors'>
      <img src={InfoOutlinedIcon} width={20} height={20}/>
      <div className='error_message_wrapper'>
      {this.state.errorMessages.map((message, i) => {
        if (message.url) {
          return (
            <a key={i} href={message.url}>
              {message.message}
            </a>
          )
        } else {
          return (
              <p key={i}>
                {message.message}
              </p>
          )
        }})}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          style={modalStyles}
          contentLabel="Reload navigation modal"
          ariaHideApp={false}
        >
          <h3>ご不便おかけして申し訳ございません</h3>
          <div>
            <p>
              <a onClick={() => location.reload()}>
                ページを読み込み直して再度お試しください
              </a>
              <a onClick={() => location.reload()}>
                <span className="icon_font icf_reload"></span>
              </a>
            </p>
          </div>
        </Modal>
        <div className="errors_wrapper">
          {this.state.errorMessages.length !== 0 && this.renderErrorMessages()}
        </div>
        <form className="login_form" action={this.props.url} acceptCharset='UTF-8' method='post' onSubmit={e => this.handleSubmit(e)}>
          <IdentifierField
              name={this.props.identifier.name}
              isError={this.state.errorMessages.length > 0}
              value={this.state.identifier}
              placeholder={this.props.identifier.placeholder}
              onChange={(value) => this.setState({identifier: value })}
            />
          <PasswordField
            name={this.props.password.name}
            isError={this.state.errorMessages.length > 0}
            placeholder={this.props.password.placeholder}
            value={this.state.password}
            onChange={(value) => this.setState({password: value })}
            togglePasswordMask={(e) => this.togglePasswordMask(e)}
            passwordMask={this.state.passwordMask}
          />
          <div className="help">
            <a href="/identity/password_resets/new">パスワードがわからない場合</a>
          </div>
          <SubmitButton loading={this.state.loading} />
          <input type='hidden' name='r' value={this.props.r} />
        </form>
      </div>
    )
  }
}
