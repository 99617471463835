import React from 'react';
import Loader from 'react-loader';

const SubmitButton = props => {
  return (
    <div className='submit_wrapper'>
      <button type='submit' disabled={props.loading} className=''>
        <span>{props.loading ? <Loader options={{scale: 0.5, color: '#fff'}} /> : 'ログイン'}</span> </button>
    </div>
  );
};

export default SubmitButton;
