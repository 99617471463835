import Sentry from './sentry';

export default class GoogleRecaptcha {
  constructor(siteKey, action) {
    this.siteKey = siteKey;
    this.action = action;
  }

  getRecaptchaToken(callback) {
    if (typeof grecaptcha !== 'undefined') {
      try {
        grecaptcha.ready(() => {
          grecaptcha.execute(this.siteKey, {action: this.action}).then(callback);
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    } else {
      Sentry.captureMessage("'grecaptcha' is undefined", {level: "warning"});
    }
  }
}
