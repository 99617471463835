import React from 'react';


const PasswordField = (props) => {
  const inputType = props.passwordMask ? 'password' : 'text';
  const toggleLabel = props.passwordMask ? '表示' : '隠す';

  return (
    <div className='input_wrapper password'> 
      <input
        type={inputType}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <a href='#' onClick={(e) => props.togglePasswordMask(e)} className='toggle'>{toggleLabel}</a>
    </div>
  );
};

export default PasswordField;
